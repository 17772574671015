<template>
	<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
		<h2>Overall Sessions</h2>
		<SessionTable split="daypart" tabletype="overall" :tbldata="tblDataDayparts.sess"></SessionTable>
		<SessionTable split="dayofweek" tabletype="overall" :tbldata="tblDataDaysOfWeek.sess"></SessionTable>

		<h2>Attributed Sessions</h2>
		<SessionTable split="daypart" tabletype="attributed" :tbldata="tblDataDayparts.attr"></SessionTable>
		<SessionTable split="dayofweek" tabletype="attributed" :tbldata="tblDataDaysOfWeek.attr"></SessionTable>

		<h2>Converted Sessions</h2>
		<SessionTable split="daypart" tabletype="converted" :tbldata="tblDataDayparts.conv"></SessionTable>
		<SessionTable split="dayofweek" tabletype="converted" :tbldata="tblDataDaysOfWeek.conv"></SessionTable>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import SessionTable from "@/components/pageviews/Dashboard/Elements/SessionTable";
import axios from "axios";

export default {
	name: "SessDiffByDaypartTable",
	components: {
		SessionTable

	},
	props: {
		p1startdate: String,
		p1enddate: String,
		p2startdate: String,
		p2enddate: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			isPreparingData: false,
			dataIsFetched: false,

			tblDataDaypartsRaw: {},
			tblDataDaysOfWeekRaw: {},
		}
	},
	computed: {
		tblDataDayparts() {
			let outdata = {sess: {}, attr: {}, conv: {}}
			if(this.dataIsFetched) {
				let uniqueDayparts = this.store.dayparts
				for (let daypart of uniqueDayparts) { //loop through ads for the day
					outdata['sess'][daypart] = this.tblDataDaypartsRaw['distsess'][daypart]
					outdata['attr'][daypart] = this.tblDataDaypartsRaw['attribsess'][daypart]
					outdata['conv'][daypart] = this.tblDataDaypartsRaw['convertedsess'][daypart]
				}
			}
			return outdata
		},
		tblDataDaysOfWeek() {
			let outdata = {sess: {}, attr: {}, conv: {}	}
			let uniqueDaysOfWeek = this.store.daysOfWeek
			if(this.dataIsFetched) {
				for (let dayOfWeek of uniqueDaysOfWeek) { //loop through ads for the day
					let mydayfilt = this.getDayOfWeekStringToShortcode(dayOfWeek)
					outdata['sess'][dayOfWeek] = this.tblDataDaysOfWeekRaw['distsess'][mydayfilt]
					outdata['attr'][dayOfWeek] = this.tblDataDaysOfWeekRaw['attribsess'][mydayfilt]
					outdata['conv'][dayOfWeek] = this.tblDataDaysOfWeekRaw['convertedsess'][mydayfilt]
				}
			}
			return outdata
		},
	},
	methods: {
		prepareTableData() {
			if(!this.isPreparingData && this.p1startdate) {
				this.isPreparingData = true
				let sendob = {
					uid: this.store.user.id,
					tasesstoken: this.store.tasesstoken,
					agencyid: this.store.selectedAgencyId,
					clientid: this.store.selectedClientId,
					start1: this.p1startdate,
					end1: this.p1enddate,
					start2: this.p2startdate,
					end2: this.p2enddate,
				};
				let self = this;
				this.store.showLoader = true;
				axios.post("post/get-comparison-period-averages.php", JSON.stringify(sendob)).then(function (response) {
					let ret = response.data;
					self.tblDataDaypartsRaw = ret.dayparts
					self.tblDataDaysOfWeekRaw = ret.daysofweek

					self.store.showLoader = false
					self.isPreparingData = false
					self.dataIsFetched = true
				})
			}
		},
	},
	watch: {
		p1startdate() { this.prepareTableData() },
		p1enddate() { this.prepareTableData() },
		p2startdate() { this.prepareTableData() },
		p2enddate() { this.prepareTableData() },
		'store.selectedClientId': {
			handler() {
				this.prepareTableData()
			}
		},
	},
	mounted() {
		this.prepareTableData()
	}
}
</script>

<style scoped>

</style>