<template>
	<div class="navbar">
		<div class="centreme flex" style="justify-content: space-between;">
			<div class="logo-holder">
				<router-link to="/dashboard">
					<img src="@/assets/login-logo.png" alt="Logo" />
				</router-link>
			</div>
			<div class="menu-item-holder">
				<slot name="navbuttons"></slot>
				<router-link to="/logout" class="logoutlink">Logout</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "NavBar",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.navbar {
	background-color: #FFF;
	height: 70px;
	box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
}
.logo-holder {
	height: 100%;
	display: flex;
	align-items: center;
}
.logo-holder img {
	height: 36px;
}
.logoutlink:hover {
	text-decoration: underline;
}
.menu-item-holder {
	display: flex;
	gap: 10px;
	align-items: center;
}
.menu-item-holder a {
	text-decoration: none;
}
</style>