<template>
	<div>
		<LoaderOverlay v-if="store.showLoader"></LoaderOverlay>
		<AlertTop v-if="store.kalert.shown"></AlertTop>
		<router-view></router-view>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import AlertTop from "@/components/AlertTop";
import LoaderOverlay from "@/components/LoaderOverlay";
import {globalMixin} from "@/mixins";

export default {
	name: 'App',
	components: {
		LoaderOverlay,
		AlertTop
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
		}
	},
	computed: {
		sessionOk() {
			return this.store.sessionOk;
		}
	},
	methods: {
		checkSessionOk() {
			let self = this;
			axios.post(
				self.store.urlroot + "post/sesscheck.php",
				JSON.stringify({
					uid: this.store.user.id,
					tasesstoken: this.store.tasesstoken,
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.sessionok !== true) { //transfer to login screen
					window.sessionStorage.removeItem('taSessToken');
					window.sessionStorage.removeItem('taUserId');
					if(self.$router.currentRoute.value.path !== '/') {
						if(window.location.pathname.length > 2 && window.location.pathname !== "/" && !window.location.pathname.includes('logout')) {
							self.$router.push("/?ref="+encodeURIComponent(window.location.pathname));
						}
						else self.$router.push("/"); //to login page
					}
				}
				else { //we are OK
					self.store.user = ret.user;
					self.store.sessionOk = true;
					if(self.$router.currentRoute.value.path === '/') { //if we are on the login page, go to home
						self.$router.push("/dashboard"); //to home landing page
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	mounted() {
		if(this.store.tasesstoken.length === 0 && window.sessionStorage.getItem('taSessToken')) {
			this.store.tasesstoken = window.sessionStorage.getItem('taSessToken');
		}
		if(this.store.user.id === 0 && window.sessionStorage.getItem('taUserId')) {
			this.store.user.id = parseInt(window.sessionStorage.getItem('taUserId'));
		}
		if(this.store.user.id > 0 && this.store.tasesstoken.length > 0) {
			this.checkSessionOk();
		}
		else if(window.location.pathname !== '/') {
			if(window.location.pathname.length > 2 && window.location.pathname !== "/" && !window.location.pathname.includes('logout')) {
				this.$router.push("/?ref="+encodeURIComponent(window.location.pathname));
			}
			else {
				this.$router.push("/"); //to login page
			}
		}
	},
	watch: {
		sessionOk() { //this will change on do-login and sesscheck (F5) once approved
			if(this.store.sessionOk === true) this.getPGInfo();
		},
	},
}
</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
	-webkit-padding-end: 0;
	-webkit-margin-after: 0;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
	font-family: 'Roboto', Arial, sans-serif;
	margin: 0;
	height: 100%;
	font-size: 13px;
	color: #444;
}
a {
	cursor: pointer;
	outline: 0;
}
input {
	font-family: inherit;
	border: none;
	padding: 5px 8px;
}
button {
	outline: 0;
	font-family: inherit;
}
h1 {
	font-size: 30px;
	font-weight: bold;
	font-family: 'Quicksand', sans-serif;
	margin: 20px 0 20px;
}
h2 {
	font-size: 20px;
	font-weight: bold;
	font-family: 'Quicksand', sans-serif;
	margin: 20px 0 10px;
}

/** working/loading buttons */
.button-is-working {
	cursor: not-allowed !important;
	color: transparent !important;
}
@keyframes xui-loader-animation {
	0%,80%,100% {transform:scale(0.5) rotate(0deg)}
	40%{transform:scale(1) rotate(0.02deg)}
}
/** end working/loading buttons */
.mx-datepicker {
	width: 216px;
}
.dater-tr.mx-datepicker {
	width: 100%;
}
.dater-tr .mx-input-wrapper {
	margin-top: 10px;
}
.dater-tr.mx-datepicker .mx-input {
	border-radius: 0;
	box-shadow: none;
	border: none;
	height: 40px;
	font-size: 13px;
	font-family: 'Open Sans', sans-serif;
}
.mainpage {
	background-color: #F0F2F5;
	min-height: 100vh;
	padding-bottom: 100px;
}
.centreme {
	max-width: 1520px;
	margin: 0 auto;
	height: 100%;
	padding: 0 40px;
}
.flex {
	display: flex;
	align-items: center;
}
.select-holder {
	width: 15%;
	display: inline-block;
	padding: 0 5px;
}
.select-holder select {
	height: 40px;
	width: 100%;
	border: none;
	background: #fff;
	font-size: 13px;
	margin-top: 10px;
	font-family: 'Open Sans', sans-serif;;
}
.select-label {
	width: 100%;
	color: #36373a;
	font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	font-size: 15px;
}
.processbtn {
	height: 40px;
	margin-left: 5px;
	background: #F38230;
	color: #fff;
	text-transform: uppercase;
	width: 140px;
	border: none;
	border-radius: 5px;
	font-family: 'Quicksand', sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 10px;
	cursor: pointer;
	letter-spacing: 0.03em;
	transition: background-color 0.3s ease-in-out;
}
.processbtn:hover {
	background: #e96c00;
}
/** chart holder */
.chart-holder {
	background-color: #FFF;
	/*border: 1px solid #CCC;*/
	height: 466px;
	width: 100%;
	margin-bottom: 20px;
}
.half {
	width: 49%;
	min-width: 49%;
}
.quarter {
	width: 24%;
	min-width: 24%;
}
.chart-rows {
	width: 100%;
	margin: 20px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.b-table-holder {
	width: 90%;
	margin: 0 auto;
}
.b-table .table {
	font-size: 14px;
}
.underline {
	text-decoration: underline;
}
/* nav buttons */
.navbtn {
	cursor: pointer;
	background-color: #DCE0E5;
	transition: all 0.3s ease-in-out;
	color: #000B2A;
	text-transform: uppercase;
	border: none;
	border-radius: 5px;
	font-family: 'Quicksand', sans-serif;
	font-size: 12px;
	font-weight: 500;
	padding: 10px 20px;
	letter-spacing: 0.03em;
	display: flex;
	justify-content: center;
	align-items: center;
}
.navbar .navbtn {
	margin-right: 10px;
	transition: none;
}
.navbtn:hover {
	background-color: #CCD0D5;
}
.navbtn.activebtn {
	background-color: #F38230;
	color: #FFF;
}
/** hotspot tables */
.hotspot-table-holder {
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}
.hotspot-table {
	border-left: 1px solid #CCC;
	width: 100%;
	table-layout: fixed;
	background-color: #FFF;
}
.hotspot-table tr {
	border-bottom: 1px solid #CCC;
}
.hotspot-table tr:first-of-type {
	border-top: 1px solid #CCC;
}
.hotspot-table td {
	border-right: 1px solid #CCC;
	padding: 8px 7px;
}
.hotspot-table td.hotspottd {
	text-align: center;
}
.hotspot-table thead {
	font-weight: bold;
	border-bottom: 2px solid #888;
	border-top: 2px solid #888;
}
.hotspot-table thead tr {

}
.hotspot-table .quintile {
	background-color: rgb(56,118,239,0.5);
}
.hotspot-table .quintile.q4 {
	background-color: rgba(56,118,239,0.3);
}
.hotspot-table .quintile.q3 {
	background-color: rgba(56,118,239,0.2);
}
.hotspot-table .quintile.q2 {
	background-color: rgba(56,118,239,0.1);
}
.align-right {
	text-align: right;
}

</style>
