<template>
	<div style="width: 100%; margin-top: 25px;">
		<ALTable :rowdata="tableData" :cols="cols" :pagination="25"></ALTable>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTable from "@/components/ALTable";

export default {
	name: "AdPlaysList",
	components: {
		ALTable
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			cols: [
				{title: 'Time Played', sortable: true, fldid: 'ad_play_local_ts'},
				{title: 'Ad Played', sortable: true, fldid: 'ad_display_name'},
				{title: 'Station', sortable: true, fldid: 'streamId',},
			],
		}
	},
	computed: {
		tableData() {
			let arr = []
			for(let ob of this.store.tbl_ad_plays_log) {
				let datearr = ob.ad_play_local_date.split("-")
				let timestr = this.getMonthName(datearr[1])+' '+datearr[2]+' '+datearr[0]+', '+ob.ad_play_local_hour+":"+this.ldzero(ob.ad_play_local_minute)
				arr.push({
					streamId: ob.streamId,
					ad_display_name: ob.ad_display_name,
					ad_play_local_ts: timestr,
				});
			}
			return arr.reverse() //make newest to oldest
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>

</style>