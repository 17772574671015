import { reactive } from 'vue'

let isDevServer = false;
let urlroot = 'https://media.tracka.app/'; //api call url
if (window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
	urlroot = 'http://tracka-ads.test/';
}

export const store = reactive({
	urlroot: urlroot,
	isDevServer: isDevServer,

	sessionOk: false,
	fetching: false,
	user: {
		id: 0,
		email: '',
	},
	tasesstoken: '',
	attrBucket: 30,

	kalert: {
		shown: false,
		message: null,
		type: 'danger',
	},

	agencies: [],
	clients: [],

	selectedAgencyId: null,
	selectedClientId: null,
	chosenOldestDate: null,
	chosenNewestDate: null,
	oldestDate: null,
	newestDate: null,
	datesInRange: [],
	weekStarts: [],
	dateHalves: {},

	metricResults: {
		distsess: 0,
		leadsess: 0,
		leadsphone: 0,
		downloads: 0,
		attr_sessions: 0,
		returnusersess: 0,
		newusersess: 0,
		convertedsess: 0,
	},
	attrSessionsChartData: [],
	summarySessionsTableWeeklyData: [],
	sessAttrByDaypartData: [],
	newVsReturningChartData: [],
	conversionChartData: [],
	attribSessConvChartData: [],
	attribSessConvChartDataSplit: [],
	attribSessLeads: [],
	distSessByDate: [],
	site_attrib_bysource: [],


	// tbl_ad_plays_log_raw: [],
	tbl_ad_plays_log: [],

	// agg_session_metrics_raw: [],
	agg_session_metrics: [],

	agg_session_landings: [],
	agg_site_conversions: [],

	activeLmenuLink: '',
	showLoader: false,
	forceMenusClose: 0,
	pgFirstFetchComplete: false,

	defaultChartColours: ['#3876EF','#5041FF','#393088','#792FA7','#B10B7B','#E2304B','#F36A10','#FFAF2E'],
	attrBuckets: [30,60,90,120,180,240,300,360,420,480,540,600,660,720,780,840,900],

	dayparts: ['1. Dawn', '2. Breakfast', '3. Morning', '4. Afternoon', '5. Drive', '6. Evening'],
	daysOfWeek: ['1. Monday', '2. Tuesday', '3. Wednesday', '4. Thursday',
		'5. Friday', '6. Saturday', '7. Sunday'
	],
})