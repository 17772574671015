<template>
	<div style="width: 100%;">
		<ALTable :rowdata="tableData" :cols="cols" :pagination="25"></ALTable>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTable from "@/components/ALTable";

export default {
	name: "SiteConversionsTable",
	components: {ALTable},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			cols: [
				{title: 'Source', sortable: true, fldid: 'source'},
				{title: 'Source Type', sortable: true, fldid: 'sourcetype'},
				{title: 'Session Starts', sortable: true, fldid: 'session_start', align: 'right', numtype: 'number'},
				{title: 'Avg Page Views', sortable: true, fldid: 'avg_page_views', align: 'right'},
				{title: 'Leads', sortable: true, fldid: 'gen_leads', align: 'right'},
				{title: 'File Downloads', sortable: true, fldid: 'file_dl', align: 'right'},
				{title: 'Session Conversion', sortable: true, fldid: 'sess_conversion', align: 'right'},
			],
		}
	},
	computed: {
		tableData() {
			let data = []
			for(let item of this.store.agg_site_conversions) {
				data.push({
					source: item.source, sourcetype: item.sourcetype, session_start: item.sess_starts,
					avg_page_views: item.avg_pageviews, gen_leads: item.leads, file_dl: item.file_downloads,
					sess_conversion: item.sess_conversion
				})
			}
			return data
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>

</style>