import axios from "axios";

export const globalMixin = {
	methods: {
		showKalert(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = ob.type;
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertError(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'danger';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		showKalertSuccess(ob, autoclose = false) {
			this.store.kalert.message = ob.message;
			this.store.kalert.type = 'success';
			this.store.kalert.shown = true;
			if(autoclose === true) {
				setTimeout(function() {
					this.store.kalert.shown = false;
				}.bind(this), 3000);
			}
		},
		closeAllMenus() {
			this.store.forceMenusClose++;
		},
		simplePost(targ, sendob, alertOnError = true) {
			sendob.uid = this.store.user.id;
			sendob.tasesstoken = this.store.tasesstoken;
			return axios.post(targ, JSON.stringify(sendob)).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (alertOnError === true && ret.error) {
					this.showKalertError(ret.error);
				}
				return ret;
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		clearLoginCredsAndSession() {
			let sendob = {uid: this.store.user.id, tasesstoken: this.store.tasesstoken};
			let self = this;
			axios.post("post/do-logout.php", JSON.stringify(sendob)).then(function (response) {
				console.log(response);
				window.sessionStorage.removeItem('taSessToken');
				window.sessionStorage.removeItem('taUserId');
				self.store.user = {
					id: 0,
					email: '',
				};
				self.store.tasesstoken = null;
				window.location = '/';
			}.bind(this)).catch(function (error) {
				console.log(error);
			});
		},
		getPGInfo() { //used on first load to populate dropdowns etc
			let sendob = {uid: this.store.user.id, tasesstoken: this.store.tasesstoken};
			let self = this;
			this.store.showLoader = true;
			axios.post("post/get-initial-objects.php", JSON.stringify(sendob)).then(function (response) {
				let ret = response.data;
				self.store.clients = ret.clients;
				self.store.agencies = ret.agencies;
				self.store.showLoader = false;
				self.store.pgFirstFetchComplete = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
		numdisplay: function (num, maxfracdigits = 0) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: maxfracdigits});
		},
		convertZero(val, replacewith = '') {
			if(!isNaN(val) && val === 0) return replacewith
			return val
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		getMonthName(monthint) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[parseInt(monthint-1)]
		},
		getUniquesByKey(arr, keyname) {
			let temparr = []
			for(let s of arr) {
				if(!temparr.includes(s[keyname])) {
					temparr.push(s[keyname])
				}
			}
			return temparr;
		},
		countUniqueByValsByKey(arr, keyname) {
			let temparr = []
			for(let s of arr) {
				temparr.push(s[keyname])
			}			return new Set(temparr).size
		},
		sumByKey(arr, keyname) {
			let val = 0
			for(let s of arr) {
				if(s[keyname] && !isNaN(s[keyname])) {
					val = val + s[keyname]
				}
			}
			return val
		},
		dateDataByKey(arr, keyname) {
			let oldestDate = null
			let newestDate = null
			let existDates = [] //the dates that exist in the array (realising that some dates may not exist)
			let allDatesInRange = [] //create every date between oldest and newest date (for charting where missing vals are a problem)
			for(let s of arr) {
				if(!existDates.includes(s[keyname])) existDates.push(s[keyname])
			}
			existDates.sort()
			oldestDate = existDates[0]
			let oldestDateOb = this.createDateOb(oldestDate)
			newestDate = existDates[existDates.length-1]
			let newestDateOb = this.createDateOb(newestDate)
			allDatesInRange = this.createDateRangeBetween(oldestDate, newestDate)
			let weeks = []
			let recentMonday = null
			for(let dt of allDatesInRange) {
				let dob = this.createDateOb(dt)
				let d = new Date(dob.y, dob.m-1, dob.d).getDay() //TODO finish this for day of week
				if(d === 1) { //mondays
					recentMonday = dt
				}
				else if(d === 0 && recentMonday) {
					weeks.push({start: recentMonday, end: dt})
					recentMonday = null
				}
			}
			return {
				oldestDate: oldestDate,
				oldestDateOb: oldestDateOb,
				newestDate: newestDate,
				newestDateOb: newestDateOb,
				existDates: existDates,
				allDatesInRange: allDatesInRange,
				weeks: weeks,
			}
		},
		createDateOb(datestr) {
			let t = datestr.split("-")
			return {y: parseInt(t[0]), m: parseInt(t[1]), d: parseInt(t[2])}
		},
		createDateRangeBetween(oldestDate, newestDate) {
			let r = []
			let oldob = this.createDateOb(oldestDate)
			let newob = this.createDateOb(newestDate)
			let daysInMonth = null
			while(oldob.y <= newob.y) {
				if(oldob.y === newob.y) { //final year of checking - make sure months are lower or equal
					while(oldob.m <= newob.m) {
						daysInMonth = this.daysInMonth(oldob.m, oldob.y)
						if(oldob.m === newob.m) { //final month in final year
							while(oldob.d <= newob.d) {
								r.push(oldob.y+"-"+this.ldzero(oldob.m)+"-"+this.ldzero(oldob.d))
								oldob.d++
							}
						}
						else { //prior months in final year
							while(oldob.d <= daysInMonth) {
								r.push(oldob.y+"-"+this.ldzero(oldob.m)+"-"+this.ldzero(oldob.d))
								oldob.d++
							}
						}
						oldob.m++
						oldob.d = 1
					}
				}
				else { //previous years - do all months
					while(oldob.m <= 12) {
						daysInMonth = this.daysInMonth(oldob.m, oldob.y)
						while(oldob.d <= daysInMonth) {
							r.push(oldob.y+"-"+this.ldzero(oldob.m)+"-"+this.ldzero(oldob.d))
							oldob.d++
						}
						oldob.m++
						oldob.d = 1
					}
				}
				oldob.y++
				oldob.m = 1
			}
			return r;
		},
		ldzero(intval) {
			if(intval < 10) return '0'+intval.toString()
			return intval.toString()
		},
		daysInMonth(mth, yr) {
			mth = parseInt(mth)
			yr = parseInt(yr)
			if(mth === 1) return 31
			if(mth === 2) {
				if(parseInt(yr/4) === yr/4) return 29
				return 28
			}
			if(mth === 3) return 31
			if(mth === 4) return 30
			if(mth === 5) return 31
			if(mth === 6) return 30
			if(mth === 7) return 31
			if(mth === 8) return 31
			if(mth === 9) return 30
			if(mth === 10) return 31
			if(mth === 11) return 30
			if(mth === 12) return 31
		},
		// sortArrayOfObjects(arr, sortColName, sortColOrder = 'asc') {
		// 	let useStrings = false;
		// 	if(isNaN(arr[0][sortColName]) || parseFloat(arr[0][sortColName]) != arr[0][sortColName]) useStrings = true;
		// 	if(sortColOrder.toLowerCase() === 'asc') {
		// 		if(useStrings === true) {
		// 			arr = arr.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
		// 		}
		// 		else arr = arr.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
		// 	}
		// 	else {
		// 		if(useStrings === true) arr = arr.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
		// 		else arr = arr.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
		// 	}
		// 	return arr;
		// },
		// getDayOfWeekStringToInt(dayOfWeekStr) {
		// 	if(dayOfWeekStr.includes('Sun')) return 0
		// 	else if(dayOfWeekStr.includes('Mon')) return 1
		// 	else if(dayOfWeekStr.includes('Tue')) return 2
		// 	else if(dayOfWeekStr.includes('Wed')) return 3
		// 	else if(dayOfWeekStr.includes('Thu')) return 4
		// 	else if(dayOfWeekStr.includes('Fri')) return 5
		// 	else if(dayOfWeekStr.includes('Sat')) return 6
		// 	return null
		// },
		getDayOfWeekStringToShortcode(dayOfWeekStr) {
			if(dayOfWeekStr.includes('Sun')) return 'Sun'
			else if(dayOfWeekStr.includes('Mon')) return 'Mon'
			else if(dayOfWeekStr.includes('Tue')) return 'Tue'
			else if(dayOfWeekStr.includes('Wed')) return 'Wed'
			else if(dayOfWeekStr.includes('Thu')) return 'Thu'
			else if(dayOfWeekStr.includes('Fri')) return 'Fri'
			else if(dayOfWeekStr.includes('Sat')) return 'Sat'
			return null
		},
		getNiceDate(str) { //YYYY-MM-DD
			if(!str || str.length === 0) return ''
			let ar = str.split("-")
			return this.getMonthName(ar[1]) + ' ' + ar[2] + ', ' + ar[0]
		},
		getChartDate(str) {
			let ar = str.split("-")
			return ar[2] + ' ' + this.getMonthName(ar[1])
		},
		getCampaignData() {
			if(this.store.fetching === false) {
				this.store.fetching = true;
				let sendob = {
					uid: this.store.user.id,
					tasesstoken: this.store.tasesstoken,
					agencyid: this.store.selectedAgencyId,
					clientid: this.store.selectedClientId,
					chosenOldestDate: this.store.chosenOldestDate,
					chosenNewestDate: this.store.chosenNewestDate,
				};
				let self = this;
				this.store.showLoader = true;
				axios.post("post/get-campaign-data.php", JSON.stringify(sendob)).then(function (response) {
					let ret = response.data;
					let oldestdate = ret.oldestDate
					let newestdate = ret.newestDate
					self.store.tbl_ad_plays_log = ret.ad_plays_log
					self.store.metricResults = ret.metricResults
					if(self.store.oldestDate !== oldestdate) self.store.oldestDate = oldestdate
					if(self.store.chosenOldestDate !== oldestdate) self.store.chosenOldestDate = oldestdate
					if(self.store.newestDate !== newestdate) self.store.newestDate = newestdate
					if(self.store.chosenNewestDate !== newestdate) self.store.chosenNewestDate = newestdate
					self.store.datesInRange = ret.datesInRange
					self.store.weekStarts = ret.weekStarts
					self.store.dateHalves = ret.dateHalves
					self.store.attrSessionsChartData = ret.attribSessPctChart
					self.store.summarySessionsTableWeeklyData = ret.summarySessionsTableWeeklyData
					self.store.sessAttrByDaypartData = ret.sessAttrByDaypartData
					self.store.newVsReturningChartData = ret.newVsReturningChartData
					self.store.conversionChartData = ret.conversionChartData
					self.store.attribSessConvChartData = ret.attribSessConvChartData
					self.store.attribSessConvChartDataSplit = ret.attribSessConvChartDataSplit
					self.store.attribSessLeads = ret.attribSessLeads
					self.store.distSessByDate = ret.distSessByDate
					self.store.site_attrib_bysource = ret.site_attrib_bysource
					self.store.agg_site_conversions = ret.agg_site_conversions
					self.store.agg_session_landings = ret.agg_session_landings

					self.store.showLoader = false;
					self.store.fetching = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	}

}