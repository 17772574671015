<template>
	<div>
		<h1>Sessions</h1>
		<div>
			<PulloutTopHeros></PulloutTopHeros>
		</div>
		<div>
			<ChartSessionsNewReturn></ChartSessionsNewReturn>
			<ChartSessionsConvert></ChartSessionsConvert>
		</div>
		<div>
			<div style="display: flex; gap: 10px; margin-bottom: 20px;">
				<p class="navbtn" :class="{activebtn : tableToggle === 'landings'}" @click="tableToggle='landings'">Landing Pages</p>
				<p class="navbtn" :class="{activebtn : tableToggle === 'siteconversions'}" @click="tableToggle='siteconversions'">Site Conversions</p>
			</div>
			<TrafficLandingPagesTable v-if="tableToggle === 'landings'"></TrafficLandingPagesTable>
			<SiteConversionsTable v-if="tableToggle === 'siteconversions'"></SiteConversionsTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import PulloutTopHeros from "@/components/pageviews/Dashboard/Elements/PulloutTopHeros";
import SiteConversionsTable from "@/components/pageviews/Dashboard/Elements/SiteConversionsTable";
import TrafficLandingPagesTable from "@/components/pageviews/Dashboard/Elements/TrafficLandingPagesTable";
import ChartSessionsConvert from "@/components/pageviews/Dashboard/Elements/ChartSessionsConvert";
import ChartSessionsNewReturn from "@/components/pageviews/Dashboard/Elements/ChartSessionsNewReturn";

export default {
	name: "DashboardSessions",
	components: {
		ChartSessionsNewReturn,
		ChartSessionsConvert,
		SiteConversionsTable,
		TrafficLandingPagesTable,
		PulloutTopHeros
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			tableToggle: 'landings',
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>