<template>
	<div>
		<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
			<PulloutNumberBox headtext="First Session" :numberstext="getNiceDate(store.oldestDate)" :numberfontsize="20" :fetching="store.fetching"></PulloutNumberBox>
			<PulloutNumberBox headtext="Most Recent Session" :numberstext="getNiceDate(store.newestDate)" :numberfontsize="20" :fetching="store.fetching"></PulloutNumberBox>
		</div>
		<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
			<div>Period 1:
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p1start" valueType="format" format="YYYY-MM-DD"></date-picker>
				<span style="display: inline-block; padding: 0 10px;">to</span>
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p1end" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>
			<div>...compare to Period 2:
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p2start" valueType="format" format="YYYY-MM-DD"></date-picker>
				<span style="display: inline-block; padding: 0 10px;">to</span>
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p2end" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>
		</div>
		<div>
			<SessDiffByDaypartTable :p1startdate="p1start" :p1enddate="p1end" :p2startdate="p2start" :p2enddate="p2end"></SessDiffByDaypartTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import SessDiffByDaypartTable from "@/components/pageviews/Dashboard/Elements/SessDiffByDaypartTable";
import PulloutNumberBox from "@/components/PulloutNumberBox";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';

export default {
	name: "DashboardTableAverages",
	components: {
		PulloutNumberBox,
		SessDiffByDaypartTable,
		DatePicker,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			p1start: null,
			p1end: null,
			p2start: null,
			p2end: null,
		}
	},
	computed: {

	},
	methods: {
		prepDates() {
			this.p1start = this.store.dateHalves.start1
			this.p1end = this.store.dateHalves.end1
			this.p2start = this.store.dateHalves.start2
			this.p2end = this.store.dateHalves.end2
		},
	},
	watch: {
		'store.dateHalves': {
			handler() {
				if(!this.p1start) {
					this.prepDates()
				}
			}
		},
	},
	mounted() {
		if(!this.p1start) {
			this.prepDates()
		}
	}
}
</script>

<style scoped>

</style>