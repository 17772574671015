<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">
		<div id="app-container">

			<div v-cloak>

				<div id="form-wrapper">
					<div>
						<div id="logo-holder">
							<a href="https://media.tracka.app">
								<img src="@/assets/login-logo.png" alt="Logo" />
							</a>
						</div>

						<div>
							<div class="form-input-group">
								<input type='email' v-model="email" placeholder="Email Address" />
								<div class="form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
							</div>
							<div v-if="showingForgetPwd === false" class="form-input-group" style="margin-bottom: 10px;">
								<input type='password' v-model="pass" placeholder="Password" />
							</div>

							<button type="button" v-if="showingForgetPwd === false" class="login-button" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
								<span>Submit</span>
								<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
							</button>

							<button v-if="showingForgetPwd === true" class="login-button" v-on:click="forgetPwd" v-bind:class="{'button-is-working':(form1working === true)}">
								<span>Resend Password</span>
								<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
							</button>

							<p v-if="showingForgetPwd === false" @click="showingForgetPwd = true" class="text-link">I've forgotten my password</p>
							<p v-if="showingForgetPwd" @click="showingForgetPwd = false" class="text-link">Cancel</p>

						</div>

					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: 'LoginPage',
	components: {
		WorkingButtonInsert
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			headerNotes: "",
			requestedLink: "", //for use after successful login

			showingForgetPwd: false,

			email: '',
			pass: '',

			checksTriggered: false,
			form1working: false,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
		passState() {
			if(this.checksTriggered === false && this.pass.length === 0) {
				return null;
			}
			else {
				if(this.pass.length < 6) return false;
				if(/[a-zA-Z]/g.test(this.pass) === false) return false;
				if(/\d/.test(this.pass) === false) return false;
			}
			return true;
		},
	},
	methods: {
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				let checkitems = [this.emailState, this.passState];
				for(let i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				let self = this;
				axios.post(
					"post/do-login.php",
					JSON.stringify({
						email: this.email,
						pass: this.pass
					})
				).then(function(response) {
					let ret = response.data; //response.data is returned info
					if(ret.error) {
						self.showKalertError(ret.error)
					}
					else if(ret.tasesstoken) {
						self.store.kalert.shown = false
						self.store.tasesstoken = ret.tasesstoken
						self.store.user = ret.user
						self.store.sessionOk = true
						window.sessionStorage.setItem('taSessToken', ret.tasesstoken)
						window.sessionStorage.setItem('taUserId', ret.user.id)
						self.$router.push("/dashboard")
					}
					self.form1working = false
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		forgetPwd() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.emailState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				let self = this;
				axios.post(
					"post/resend-password.php",
					JSON.stringify({
						email: this.email,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					self.form1working = false;
					if(ret.error === 1) {
						self.showKalertError(ret);
					}
					else if(ret.success === 1) {
						self.showKalertSuccess(ret);
						self.showingForgetPwd = false;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Tracka Media Analysis - Login";
	},
	created() {
		//handle incoming GET parameters
		// let urlparams = this.$route.query;
		// if(urlparams.activated !== undefined && parseInt(urlparams.activated) === 1) {
		// 	this.headerNotes = "Thanks for activating your account - you can now log in!";
		// }
		// if(urlparams.passreset !== undefined && parseInt(urlparams.passreset) === 1) {
		// 	this.headerNotes = "Your password has been changed - you can now log in!";
		// }
	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
.bgpage {
	background-image: url('~@/assets/login_bg-img.jpg');
	background-size: cover;
}
#app-container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	margin-bottom: 30px;
}
#logo-holder img {
	height: 52px;
}
#form-wrapper {
	padding: 60px;
	margin-top: 40px;
	margin-bottom: 80px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #dce0e5;
	border-radius: 10px;
}
.form-input-group {
	margin-bottom: 20px;
	border-radius: 6px;
	height: 80px;
	font-size: 18px;
}
.form-input-fail {
	color: #F00;
	font-size: 12px;
	text-align: left;
	display: none;
}
.form-input-fail.shown {
	display: block;
}
#form-wrapper input {
	margin-bottom: 10px;
	border-radius: 6px;
	height: 50px;
	font-size: 18px;
	width: calc(100% - 16px);
}
.login-button {
	margin-bottom: 20px;
	border-radius: 6px;
	height: 60px;
	background: #f5821f;
	color: #fff;
	font-size: 22px;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	transition: ease-in-out 0.5s;
	width: 100%;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login-button:hover {
	background: #e96c00;
}
.text-link {
	cursor: pointer;
	width: fit-content;
	padding-bottom: 1px;
	margin: 0 auto;
	border-bottom: 1px dotted transparent;
	transition: ease-in-out 0.5s;
}
.text-link:hover {
	border-bottom: 1px dotted #666;
}
</style>
