<template>
	<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
		<ChartHolderDash :height="420" addclasses="">
			<template v-slot:default>
				<apexchart v-if="chartData.length > 0" width="99%" height="100%" type="bar"
					:options="chart_options" :series="chartData"></apexchart>
				<ChartLoaderInsert v-if="store.fetching"></ChartLoaderInsert>
			</template>
		</ChartHolderDash>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "AdPlaysChart",
	components: {
		ChartLoaderInsert,
		ChartHolderDash

	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chart_options: {
				chart: {
					id: "adplays",
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					stacked: true,
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: [
					{title: {text: 'Plays'}, decimalsInFloat: 0, min: 0},
				],
				colors: store.defaultChartColours,
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 0
				},
			},
		}
	},
	computed: {
		chartData() {
			let outdata = {}
			let uniqueAds = this.getUniquesByKey(this.store.tbl_ad_plays_log, 'ad_display_name')
			for(let d of this.store.datesInRange) { //d is datestring YYYY-MM-DD
				let tblsubset = this.store.tbl_ad_plays_log.filter(item => item.ad_play_local_date === d) //a single day's data
				for(let ad_name of uniqueAds) { //loop through ads for the day
					let t = tblsubset.filter(item => item.ad_display_name === ad_name)
					if(!outdata[ad_name]) {
						outdata[ad_name] = {name: ad_name, type: 'column', data: []}
					}
					outdata[ad_name]['data'].push({x: this.getChartDate(d), y: t.length})
				}
			}
			let newoutdata = []
			for(let od in outdata) {
				newoutdata.push(outdata[od])
			}
			return newoutdata
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>