<template>
	<div v-if="summarypage" style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
		<PulloutNumberBox v-if="summarypage" headtext="Total Ads Played" :numberstext="numdisplay(adcount)" :fetching="store.fetching"></PulloutNumberBox>
		<PulloutNumberBox v-if="summarypage" headtext="Attributed Sessions" :numberstext="numdisplay(store.metricResults.attr_sessions)" :fetching="store.fetching"></PulloutNumberBox>
		<PulloutNumberBox v-if="summarypage" headtext="% Attributed" :ispct="true" :numberstext="numdisplay(attrpct)" :fetching="store.fetching"></PulloutNumberBox>
	</div>
	<div style="padding: 0px 0 20px; display: flex; flex-wrap: wrap; gap: 20px;">
		<PulloutNumberBox headtext="Sessions" :numberstext="numdisplay(store.metricResults.distsess)" :fetching="store.fetching"></PulloutNumberBox>
		<PulloutNumberBox headtext="Leads (webform)" :numberstext="numdisplay(store.metricResults.leadsess)" :fetching="store.fetching"></PulloutNumberBox>
		<PulloutNumberBox headtext="Leads (phone)" :numberstext="numdisplay(store.metricResults.leadsphone)" :fetching="store.fetching"></PulloutNumberBox>
		<PulloutNumberBox headtext="Downloads" :numberstext="numdisplay(store.metricResults.downloads)" :fetching="store.fetching"></PulloutNumberBox>
	</div>
</template>

<script>
import PulloutNumberBox from "@/components/PulloutNumberBox";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "PulloutTopHeros",
	components: {
		PulloutNumberBox
	},
	props: {
		summarypage: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		adcount() {
			return this.store.tbl_ad_plays_log.length;
		},
		attrpct() {
			if(this.store.metricResults.attr_sessions === 0 || this.store.metricResults.distsess === 0) return ''
			return (this.store.metricResults.attr_sessions / this.store.metricResults.distsess * 100).toFixed(1)
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>