<template>
	<div style="width: 100%; position: relative;">
<!--		<div v-if="excelatbottom !== true && ((searchbox && searchbox === true) || (excel && excel === true))" style="display: flex; align-items: center; justify-content: space-between; padding: 5px 0;">-->
<!--			<div v-if="searchbox && searchbox === true" class="" style="width: 300px; margin: 5px 0;">-->
<!--				<input type="text" class="text-field-input" v-model="searchboxval" placeholder="Search" />-->
<!--			</div>-->
<!--			<div v-if="excel && excel === true">-->
<!--				<span @click="exportToExcel(tblRandId)" class="excel-link">-->
<!--					<i class="mdi mdi-file-excel-outline"></i>-->
<!--				</span>-->
<!--			</div>-->
<!--		</div>-->
		<table v-if="cols && cols.length > 0 && rowdata && rowdata.length > 0" class="altbl" :class="tblClassesComputed" :id="tblRandId">
			<thead>
				<tr>
					<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" :class="getColClasses(col)" data-f-bold="true">
						<span>{{col.title}}</span>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(rowitem, rowindex) in rowsForPage" :key="rowindex">
					<td v-for="c in cols" :key="c.title" :class="getColClasses(c, rowindex)">
						<span v-html="getCellVal(rowitem, c, rowindex)"></span>
					</td>
				</tr>
			</tbody>
		</table>
		<div v-if="(pagination && this.paginatedRows.length > 1) || (excelatbottom === true && excel && excel === true)">
			<div v-if="pagination && this.paginatedRows.length > 1" style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>
			<!--		<div v-if="excelatbottom === true && excel && excel === true" style="display: flex; justify-content: flex-end; padding: 5px 0;">-->
			<!--			<span @click="exportToExcel(tblRandId)" class="excel-link">-->
			<!--				<i class="mdi mdi-file-excel-outline"></i>-->
			<!--			</span>-->
			<!--		</div>-->
		</div>
	</div>
</template>

<script>
// import TableToExcel from "@linways/table-to-excel";
import {globalMixin} from "@/mixins";
import ALTablePaginator from "@/components/pageviews/Dashboard/ALTablePaginator";

export default {
	name: "ALTable",
	components: {
		ALTablePaginator
	},
	props: {
		rowdata: Array,
		cols: Array,
		meta: Array,
		tblclasses: String,
		excel: Boolean,
		excelfilename: String,
		excelatbottom: Boolean,
		searchbox: Boolean,
		pagination: Number,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			sortColName: null,
			sortColOrder: 'desc',
			tblRandId: null,
			searchboxval: '',
			paginationPage: 1,
		}
	},
	computed: {
		rows() {
			let rows = this.rowdata;
			if(this.searchboxval.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = this.searchboxval.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(isNaN(r[fld]) || parseFloat(r[fld]) != r[fld]) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			if(this.sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][this.sortColName]) || parseFloat(rows[0][this.sortColName]) != rows[0][this.sortColName]) useStrings = true;
				if(this.sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[this.sortColName].toLowerCase() > b[this.sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[this.sortColName] > b[this.sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[this.sortColName].toLowerCase() > b[this.sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[this.sortColName] > b[this.sortColName]) ? -1 : 1)
				}
			}

			return rows;
		},
		paginatedRows() {
			if(!this.pagination) return [this.rows]
			else {
				let tempArr = []
				let tempSubArr = []
				for(let it of this.rows) {
					tempSubArr.push(it)
					if(tempSubArr.length === this.pagination) {
						tempArr.push(tempSubArr)
						tempSubArr = []
					}
				}
				if(tempSubArr.length > 0) tempArr.push(tempSubArr)
				return tempArr
			}
		},
		rowsForPage() {
			return this.paginatedRows[this.paginationPage-1]
		},
		tblClassesComputed() {
			let c = '';
			if(this.tblclasses && this.tblclasses.length > 0) c += this.tblclasses + ' ';
			return c;
		},
	},
	methods: {
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		// exportToExcel(tblid, filename = 'export') {
		// 	if(this.excelfilename && this.excelfilename.length > 0) {
		// 		filename = this.excelfilename;
		// 	}
		// 	TableToExcel.convert(document.getElementById(tblid), {
		// 		name: filename+".xlsx",
		// 		sheet: {
		// 			name: 'Sheet 1'
		// 		}
		// 	});
		// },
		getCellVal(rowitem, colitem, rowindex = -1) {
			let fldid = colitem.fldid;
			let val = rowitem[fldid];
			if(colitem.numtype && colitem.numtype === 'number') val = this.numdisplay(val);
			else if(colitem.numtype && colitem.numtype === 'pct') {
				if(val != '') val = this.numdisplay(val)+"%";
			}
			else if(colitem.numtype && (colitem.numtype === 'pctbubble' || colitem.numtype === 'numbubble')) {
				let h = '';
				if(val !== '' && val !== 0) {
					h += '<span class="bubble';
					if(val < 0) h += ' down';
					h += '">';
					if(val > 0) h += '+';
					if(val != '') h += this.numdisplay(val);
					if(colitem.numtype === 'pctbubble') h += "%";
					h += '</span>';
				}
				return h;
			}
			if(this.meta && rowindex >= 0 && this.meta[rowindex][colitem.fldid] && this.meta[rowindex][colitem.fldid]['cellhtml']) {
				if(this.meta[rowindex][colitem.fldid]['cellhtml'].length > 0) {
					return this.meta[rowindex][colitem.fldid]['cellhtml']
				}
			}
			return val;
		},
		getColClasses(col, rowindex = -1) {
			let c = '';
			if(col.align === 'right' || col.numtype && ['number','pct','pctbubble'].indexOf(col.numtype) > -1) c += 'rt ';
			if(['center','centre','ctr'].includes(col.align)) c += 'ctr ';
			if(this.meta && rowindex >= 0) { //meta is set, and we are in tbody not thead/colheaders
				if(this.meta[rowindex][col.fldid] && this.meta[rowindex][col.fldid]['tdclasses']) c += this.meta[rowindex][col.fldid]['tdclasses'] + ' '
			}
			return c;
		},
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
	},
	watch: {},
	created() {
		if(this.tblRandId === null) {
			this.tblRandId = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
		}
	},
	mounted() {

	}
}
</script>

<style scoped>
.altbl {
	width: 100%;
}
.altbl thead td {
	cursor: pointer;
}
.altbl td {
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
	padding: 8px;
	word-wrap: break-word;
	word-break: break-word;
}
.altbl.noborderv td {
	border-left: none;
	border-right: none;
}
.altbl thead {
	border-top: 2px solid #888;
	border-bottom: 2px solid #888;
}
.altbl thead tr {
	background-color: #FFF;
}
.altbl thead tr td {
	padding: 10px 8px 9px;
}
.altbl tbody tr {
	background-color: #FFF;
}
.altbl tbody tr:nth-of-type(odd) {
	background-color: rgba(0,0,0,0.05);
}
.altbl tbody tr:hover {
	background-color: rgba(0,0,0,0.075);
}
.altbl .subtitlerow {
	background-color: #EEE;
	font-weight: bold;
	border-top: 1px solid #888;
	border-bottom: 1px solid #888;
}
.altbl tr.subtitlerow td {
	padding: 10px 8px 9px;
}
.altbl .headingtext {
	font-weight: bold;
}
.altbl .rt {
	text-align: right;
}
.altbl .ctr {
	text-align: center;
}
.excel-link {
	cursor: pointer;
	padding: 4px 0;
}
.excel-link i {
	font-size: 20px;
}

</style>