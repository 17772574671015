<template>
	<div class="hotspot-table-holder">
		<ALTable :pagination="25" :cols="cols" :rowdata="tblData" :meta="tblMeta" tblclasses="hotspot-table"></ALTable>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ALTable from "@/components/ALTable";

export default {
	name: "AdPlaysByDaypartTable",
	components: {
		ALTable,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		dateinf() {
			return this.dateDataByKey(this.store.tbl_ad_plays_log, 'ad_play_local_date') //YYYY-MM-DD
		},
		dayparts() {
			return this.getUniquesByKey(this.store.tbl_ad_plays_log, 'session_day_part').sort()
		},
		cols() {
			let c = [
				{title: 'Date', sortable: true, fldid: 'dt'},
			];
			for(let dp of this.dayparts) {
				c.push({title: dp, sortable: false, fldid: dp, align: 'center'})
			}
			return c
		},
		tblData() {
			let outdata = {}
			let uniqueDayparts = this.dayparts
			for(let d of this.dateinf.allDatesInRange) { //d is datestring YYYY-MM-DD
				let tblsubset = this.store.tbl_ad_plays_log.filter(item => item.ad_play_local_date === d) //a single day's data
				for(let daypart of uniqueDayparts) { //loop through ads for the day
					let t = tblsubset.filter(item => item.session_day_part === daypart)
					if(!outdata[d]) {
						outdata[d] = {dt: d}
					}
					outdata[d][daypart] = t.length
				}
			}
			let newoutdata = []
			for(let od in outdata) {
				newoutdata.push(outdata[od])
			}
			return newoutdata.reverse() //make newest to oldest
		},
		arrayOfTableValSplits() {
			let arr = []
			for(let r of this.tblData) {
				for(let k in r) {
					let val = r[k]
					if(!isNaN(val)) arr.push(val)
				}
			}
			let split = Math.floor(arr.length / 5)
			arr = arr.sort()
			return [
				arr[split],
				arr[split*2],
				arr[split*3],
				arr[split*4]
			]
		},
		tblMeta() {
			let arr = []
			for(let r in this.tblData) { //r is index
				if(!arr[r]) arr[r] = {}
				for(let k in this.tblData[r]) {
					if(k === 'dt') continue
					let val = parseFloat(this.tblData[r][k])
					if(!arr[r][k]) arr[r][k] = {}
					//add any classes we want to the TD parent
					if(!isNaN(val)) arr[r][k]['tdclasses'] = 'hotspottd ' + this.getquintile(val)
					//add any custom rendered HTML for the cell if desired
					// if(!isNaN(val)) arr[r][k]['cellhtml'] = '<span style="color: pink;">'+val+'</span>'
				}
			}
			return arr
		},
	},
	methods: {
		getquintile(val) {
			val = parseFloat(val)
			if(val === 0 || isNaN(val)) return ''
			else if(val > this.arrayOfTableValSplits[3]) return 'quintile q5'
			else if(val > this.arrayOfTableValSplits[2]) return 'quintile q4'
			else if(val > this.arrayOfTableValSplits[1]) return 'quintile q3'
			else if(val > this.arrayOfTableValSplits[0]) return 'quintile q2'
			return ''
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>