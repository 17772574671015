<template>
	<div class="mainpage">
		<NavBar>
			<template v-slot:navbuttons>
				<span class="navbtn" @click="switchView('summary')" :class="{activebtn : activeView === 'summary'}">Summary</span>
				<span class="navbtn" @click="switchView('sessions')" :class="{activebtn : activeView === 'sessions'}">Sessions</span>
				<span class="navbtn" @click="switchView('adsplayed')" :class="{activebtn : activeView === 'adsplayed'}">Ads Played</span>
				<span class="navbtn" @click="switchView('attribution')" :class="{activebtn : activeView === 'attribution'}">Attribution</span>
				<span class="navbtn" @click="switchView('averages')" :class="{activebtn : activeView === 'averages'}">Data Averages</span>
			</template>
		</NavBar>
		<div class="centreme">
			<div style="padding: 30px 0 20px; display: flex; justify-content: space-between;">
				<div style="display: flex; flex-grow: 1; align-items: center;">
					<div class="select-holder">
						<span class="select-label">Agency</span>
						<select v-model="store.selectedAgencyId">
							<option value="0"></option>
							<option v-for="c in store.agencies" :key="c.id" :value="c.id">{{c.name}}</option>
						</select>
					</div>
					<div class="select-holder">
						<span class="select-label">Client/Brand</span>
						<select v-model="store.selectedClientId">
							<option v-for="c in availableClientsForAgency" :key="c.id" :value="c.id">{{c.name}}</option>
						</select>
					</div>
<!--					<div style="align-self: flex-end;">-->
<!--						<button @click="getData" class="processbtn">Fetch</button>-->
<!--					</div>-->
					<div class="select-holder">
						<span class="select-label">View From</span>
						<date-picker class="dater-tr" v-model:value="store.chosenOldestDate" valueType="format" format="YYYY-MM-DD"></date-picker>
					</div>
					<div class="select-holder">
						<span class="select-label">View To</span>
						<date-picker class="dater-tr" v-model:value="store.chosenNewestDate" valueType="format" format="YYYY-MM-DD"></date-picker>
					</div>
				</div>
			</div>

			<div >
				<DashboardSummary v-if="activeView === 'summary'"></DashboardSummary>
				<DashboardSessions v-if="activeView === 'sessions'"></DashboardSessions>
				<DashboardAdPlays v-if="activeView === 'adsplayed'"></DashboardAdPlays>
				<DashboardAttribution v-if="activeView === 'attribution'"></DashboardAttribution>
				<DashboardTableAverages v-if="activeView === 'averages'"></DashboardTableAverages>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import NavBar from "@/components/NavBar";
import DashboardSessions from "@/components/pageviews/Dashboard/DashboardSessions";
import DashboardAdPlays from "@/components/pageviews/Dashboard/DashboardAdPlays";
import DashboardAttribution from "@/components/pageviews/Dashboard/DashboardAttribution";
import DashboardTableAverages from "@/components/pageviews/Dashboard/DashboardTableAverages";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import DashboardSummary from "@/components/pageviews/Dashboard/DashboardSummary";

export default {
	name: 'DashboardPage',
	components: {
		DashboardSummary,
		DashboardTableAverages,
		DashboardAttribution,
		DashboardAdPlays,
		DashboardSessions,
		NavBar,
		DatePicker,
	},
	props: {
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			activeView: 'summary', //sessions, adsplayed
		}
	},
	computed: {
		pgFirstFetchComplete() {
			return this.store.pgFirstFetchComplete
		},
		availableClientsForAgency() {
			if(this.store.selectedAgencyId && this.store.clients.length > 0) {
				return this.store.clients.filter(item => item.agencyId === this.store.selectedAgencyId)
			}
			return [];
		},
	},
	methods: {
		switchView(newtab) {
			this.activeView = newtab
		},
	},
	watch: {
		'store.chosenOldestDate': {
			handler() {
				if (this.store.fetching === false && this.store.chosenOldestDate !== this.store.oldestDate) this.getCampaignData();
			}
		},
		'store.chosenNewestDate': {
			handler() {
				if (this.store.fetching === false && this.store.chosenNewestDate !== this.store.newestDate) this.getCampaignData();
			}
		},
		'store.selectedAgencyId': {
			handler() {
				if(this.store.selectedAgencyId && this.store.selectedAgencyId > 0 && this.availableClientsForAgency && this.availableClientsForAgency.length > 0) {
					this.store.selectedClientId = this.availableClientsForAgency[0].id
				}
			}
		},
		'store.selectedClientId': {
			handler() {
				if (this.store.selectedClientId && this.store.selectedClientId > 0 && this.store.selectedAgencyId && this.store.selectedAgencyId > 0) {
					this.getCampaignData();
				}
			}
		},
		pgFirstFetchComplete() {
			if(this.pgFirstFetchComplete === true && (!this.store.selectedAgencyId || this.store.selectedAgencyId === 0) && this.store.agencies && this.store.agencies.length > 0) {
				this.store.selectedAgencyId = this.store.agencies[0].id
				if(this.availableClientsForAgency && this.availableClientsForAgency.length > 0) {
					this.store.selectedClientId = this.availableClientsForAgency[0].id
				}
			}
		},
	},
	mounted() {
		document.title = "Tracka Media Analysis";
	},
	created() {

	},
}
</script>

<style scoped>

</style>
