<template>
	<div>
		<h1>Ads Played</h1>
		<div>
			<AdPlaysChart></AdPlaysChart>
		</div>
		<div>
			<h2>Daypart Ad Plays</h2>
			<AdPlaysByDaypartTable></AdPlaysByDaypartTable>
		</div>
		<div style="margin-top: 70px">
			<h2>List of Ad Plays</h2>
			<AdPlaysList></AdPlaysList>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import AdPlaysChart from "@/components/pageviews/Dashboard/Elements/AdPlaysChart";
import AdPlaysByDaypartTable from "@/components/pageviews/Dashboard/Elements/AdPlaysByDaypartTable";
import AdPlaysList from "@/components/pageviews/Dashboard/Elements/AdPlaysList";

export default {
	name: "DashboardAdPlays",
	components: {
		AdPlaysList,
		AdPlaysByDaypartTable,
		AdPlaysChart
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>