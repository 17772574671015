<template>
	<div class="hotspot-table-holder" style="padding-top: 0;">
		<table class="hotspot-table">
			<thead>
				<tr>
					<td>{{firscolheader}}</td>
					<td v-for="text in colheaders" :key="text" class="align-right">{{text}}</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, key, index) in tbldata" :key="index">
					<td>{{key}}</td>
					<td class="align-right">{{numdisplay(item.pre, 2)}}</td>
					<td class="align-right">{{numdisplay(item.dur, 2)}}</td>
					<td class="align-right">{{numdisplay(item.delta)}}%</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SessionTable",
	components: {

	},
	props: {
		tabletype: String, //overall, converted, attributed
		split: String, //daypart, dayofweek
		tbldata: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		firscolheader() {
			if(this.split === 'daypart') return 'Daypart'
			else if(this.split === 'dayofweek') return 'Day of Week'
			return ''
		},
		colheaders() {
			let cols = []
			if(this.tabletype === 'overall') {
				cols.push('Period 1 Avg Daily Sessions')
				cols.push('Period 2 Avg Daily Sessions')
			}
			else if(this.tabletype === 'attributed') {
				cols.push('Period 1 Avg Attributed Daily Sessions')
				cols.push('Period 2 Avg Attributed Daily Sessions')
			}
			else if(this.tabletype === 'converted') {
				cols.push('Period 1 Avg Converted Daily Sessions')
				cols.push('Period 2 Avg Converted Daily Sessions')
			}
			cols.push('Difference %')
			return cols
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>