<template>
	<div style="width: 100%; margin-top: 30px;" class="landing-path-table">
		<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
			<div>Period 1:
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p1start" valueType="format" format="YYYY-MM-DD"></date-picker>
				<span style="display: inline-block; padding: 0 10px;">to</span>
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p1end" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>
			<div>...compare to Period 2:
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p2start" valueType="format" format="YYYY-MM-DD"></date-picker>
				<span style="display: inline-block; padding: 0 10px;">to</span>
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="p2end" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>
		</div>

		<ALTable :rowdata="tableData" :cols="cols" :pagination="25"></ALTable>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTable from "@/components/ALTable";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import axios from "axios";

export default {
	name: "SummaryActivityCompareTable",
	components: {
		ALTable,
		DatePicker
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			p1start: null,
			p1end: null,
			p2start: null,
			p2end: null,

			// tableData: [],
			isPreparingData: false,

			logPlayData: [],
			otherData: [],
		}
	},
	computed: {
		tableData() {
			return this.logPlayData.concat(this.otherData)
		},
		cols() {
			let p1header = 'Period 1'
			if(this.p1start && this.p1end) p1header = this.getNiceDate(this.p1start)+ ' to '+this.getNiceDate(this.p1end)
			let p2header = 'Period 2'
			if(this.p2start && this.p2end) p2header = this.getNiceDate(this.p2start)+ ' to '+this.getNiceDate(this.p2end)
			return [
				{title: 'Activity', sortable: false, fldid: 'name'},
				{title: p1header, sortable: false, fldid: 'p1'},
				{title: p2header, sortable: false, fldid: 'p2'},
			]
		},
		uniqueAds() {
			return this.getUniquesByKey(this.store.tbl_ad_plays_log, 'ad_display_name')
		},
	},
	methods: {
		prepareTableData() {
			if(!this.isPreparingData && this.p1start) {
				this.isPreparingData = true
				let data = []
				let sendob = {
					uid: this.store.user.id,
					tasesstoken: this.store.tasesstoken,
					agencyid: this.store.selectedAgencyId,
					clientid: this.store.selectedClientId,
					start1: this.p1start,
					end1: this.p1end,
					start2: this.p2start,
					end2: this.p2end,
				};
				let self = this;
				this.store.showLoader = true;
				axios.post("post/get-comparison-period-data.php", JSON.stringify(sendob)).then(function (response) {
					let ret = response.data;
					if(ret.outdata) {
						data.push({name: 'Web Sessions', p1: ret.outdata.p1.distsess, p2: ret.outdata.p2.distsess})
						data.push({name: 'Leads (webform completed)', p1: ret.outdata.p1.leadsess, p2: ret.outdata.p2.leadsess})
					}
					self.otherData = data
					self.store.showLoader = false
					self.isPreparingData = false
					self.updateLogPlaysTableData()
				})
			}
		},
		updateLogPlaysTableData() {
			this.logPlayData = []
			if(this.uniqueAds.length > 0) {
				for (let ad of this.uniqueAds) {
					let p1adsubset = this.store.tbl_ad_plays_log.filter(item => item.ad_play_local_date >= this.p1start && item.ad_play_local_date <= this.p1end)
					let p2adsubset = this.store.tbl_ad_plays_log.filter(item => item.ad_play_local_date >= this.p2start && item.ad_play_local_date <= this.p2end)
					let p1a = p1adsubset.filter(item => item.ad_display_name === ad).length
					let p2a = p2adsubset.filter(item => item.ad_display_name === ad).length
					this.logPlayData.push({name: ad + ' plays', p1: p1a, p2: p2a})
				}
			}
		},
		prepDates() {
			this.p1start = this.store.dateHalves.start1
			this.p1end = this.store.dateHalves.end1
			this.p2start = this.store.dateHalves.start2
			this.p2end = this.store.dateHalves.end2
		},
	},
	watch: {
		'store.dateHalves': {
			handler() {
				if(!this.p1start) {
					this.prepDates()
				}
			}
		},
		p1start() { this.prepareTableData() },
		p1end() { this.prepareTableData() },
		p2start() { this.prepareTableData() },
		p2end() { this.prepareTableData() },
		'store.selectedClientId': {
			handler() {
				this.prepareTableData()
			}
		},
		uniqueAds() {
			this.prepareTableData()
		},
	},
	mounted() {
		if(!this.p1start) {
			this.prepDates()
		}
	}
}
</script>
<style scoped>

</style>