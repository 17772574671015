<template>
	<div style="width: 100%; margin-top: 30px;" class="landing-path-table">
		<ALTable :rowdata="tableData" :cols="cols" :pagination="25"></ALTable>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTable from "@/components/ALTable";

export default {
	name: "SummarySessionsTableWeekly",
	components: {
		ALTable
	},
	props: {
		weeks: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			cols: [
				{title: 'Week', sortable: false, fldid: 'name'},
				{title: 'Web Sessions', sortable: true, fldid: 'sess', align: 'right', numtype: 'number'},
				{title: 'Downloads', sortable: true, fldid: 'downloads', align: 'right', numtype: 'number'},
				{title: 'Leads', sortable: true, fldid: 'leads', align: 'right', numtype: 'number'},
			],
		}
	},
	computed: {
		tableData() {
			return this.store.summarySessionsTableWeeklyData.slice(-4)
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>

</style>