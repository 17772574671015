<template>
	<div>
		<div>
			<PulloutTopHeros></PulloutTopHeros>
		</div>
		<div>
			<h2>Ads Played</h2>
			<AdPlaysChart></AdPlaysChart>
		</div>
		<div>
			<h2>Attributed Sessions & Conversion</h2>
			<ChartSessionsAttributed></ChartSessionsAttributed>
			<ChartSessionsAttributedSplit></ChartSessionsAttributedSplit>
		</div>
		<div>
			<h2>Converted Sessions</h2>
			<ChartSessionsDownloadLead></ChartSessionsDownloadLead>
			<ChartSessionsDownloadLeadSplit></ChartSessionsDownloadLeadSplit>
		</div>
		<div>
			<h2 style="margin-bottom: 25px;">Attribution by Source</h2>
			<SiteAttributionTable></SiteAttributionTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import PulloutTopHeros from "@/components/pageviews/Dashboard/Elements/PulloutTopHeros";
import AdPlaysChart from "@/components/pageviews/Dashboard/Elements/AdPlaysChart";
import ChartSessionsAttributed from "@/components/pageviews/Dashboard/Elements/ChartSessionsAttributed";
import ChartSessionsDownloadLead from "@/components/pageviews/Dashboard/Elements/ChartSessionsDownloadLead";
import SiteAttributionTable from "@/components/pageviews/Dashboard/Elements/SiteAttributionTable";
import ChartSessionsAttributedSplit from "@/components/pageviews/Dashboard/Elements/ChartSessionsAttributedSplit";
import ChartSessionsDownloadLeadSplit from "@/components/pageviews/Dashboard/Elements/ChartSessionsDownloadLeadSplit";

export default {
	name: "DashboardAttribution",
	components: {
		ChartSessionsDownloadLeadSplit,
		ChartSessionsAttributedSplit,
		SiteAttributionTable,
		ChartSessionsDownloadLead,
		ChartSessionsAttributed,
		AdPlaysChart,
		PulloutTopHeros
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>