<template>
	<div class="pullout-box">
		<div style="">
			<p class="head-text">{{headtext}}</p>
			<div class="numbers" :style="'font-size: '+this.numfontsize">
				<span v-if="fetching === false">{{numcontent}}</span>
				<div v-else style="height: 50px;">
					<ChartLoaderInsert></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "PulloutNumberBox",
	components: {
		ChartLoaderInsert
	},
	props: {
		headtext: String,
		numberstext: {type: [Number, String]},
		fetching: Boolean,
		numberfontsize: Number,
		ispct: Boolean,
	},
	data: function () {
		return {

		}
	},
	computed: {
		numcontent() {
			if(this.numberstext && (this.numberstext.length > 0 || this.numberstext > 0)) {
				if(this.ispct === true) return this.numberstext + "%"
				else return this.numberstext
			}
			return '';
		},
		numfontsize() {
			if(this.numberfontsize) return this.numberfontsize+'px'
			return '50px'
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.pullout-box {
	background-color: #DCE0E5;
	color: #000B2A;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 180px;
	min-width: 200px;
	flex: 1;
}
.head-text {
	font-size: 18px;
	margin-bottom: 10px;
	font-family: 'Roboto';
}
.numbers {
	position: relative;
	display: flex;
	justify-content: center;
	font-family: 'Quicksand';
}
</style>