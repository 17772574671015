<template>
	<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
		<ChartHolderDash :height="420" addclasses="">
			<template v-slot:default>
				<apexchart v-if="chartData.length > 0" width="99%" height="100%"
					:options="chart_options" :series="chartData"></apexchart>
				<ChartLoaderInsert v-if="store.fetching"></ChartLoaderInsert>
			</template>
		</ChartHolderDash>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "ChartSessionsNewReturn",
	components: {
		ChartLoaderInsert,
		ChartHolderDash

	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chart_options: {
				chart: {
					id: "sess_date",
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					stacked: true,
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: [
					{title: {text: 'Sessions'}, decimalsInFloat: 0, min: 0},
				],
				colors: ['#FFAF2E','#F36A10'],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 0
				},
			},
		}
	},
	computed: {
		chartData() {
			return this.store.newVsReturningChartData
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>