<template>
	<div style="padding: 20px 0; display: flex; flex-wrap: wrap; gap: 20px;">
		<ChartHolderDash :height="420" addclasses="">
			<template v-slot:default>
				<apexchart v-if="chartData.length > 0" width="99%" height="100%" type="bar"
					:options="chart_options" :series="chartData"></apexchart>
				<ChartLoaderInsert v-if="store.fetching"></ChartLoaderInsert>
			</template>
		</ChartHolderDash>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "ChartSessionsDownloadLeadSplit",
	components: {
		ChartLoaderInsert,
		ChartHolderDash

	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chart_options: {
				chart: {
					id: "sess_download_lead",
					toolbar: {
						show: true
					},
					animations: {
						enabled: true
					},
					stacked: true,
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: [
					{title: {text: 'Sessions'}, decimalsInFloat: 0, min: 0},
					{opposite: true, title: {text: 'Ads Played'}, decimalsInFloat: 0, min: 0},
				],
				colors: ['#792FA7','#3876EF','#E2304B'],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 0
				},
			},
		}
	},
	computed: {
		chartData() {
			let totsessData = []
			let adCountData = []
			for(let d of this.store.datesInRange) { //d is datestring YYYY-MM-DD
				let distval = 0
				let sessob = this.store.distSessByDate.find(item => item.date === d)
				if(sessob) {
					distval = sessob.sess
				}
				totsessData.push({x: this.getChartDate(d), y: distval})

				let adtblsubset = this.store.tbl_ad_plays_log.filter(item => item.ad_play_local_date === d) //a single day's data
				adCountData.push({x: this.getChartDate(d), y: this.countUniqueByValsByKey(adtblsubset, 'query_id')})
			}
			return [
				{name: 'Sessions', type: 'column', data: totsessData},
				{name: 'Ads Played', type: 'line', data: adCountData},
			]
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>