<template>
	<div style="width: 100%;" class="landing-path-table">
		<ALTable :rowdata="tableData" :cols="cols" :pagination="25"></ALTable>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import ALTable from "@/components/ALTable";

export default {
	name: "TrafficLandingPagesTable",
	components: {ALTable},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			cols: [
				{title: 'Landing Page Path', sortable: true, fldid: 'name'},
				{title: 'Sessions', sortable: true, fldid: 'val', align: 'right', numtype: 'number'},
			],
		}
	},
	computed: {
		tableData() {
			let data = []
			for(let item of this.store.agg_session_landings) {
				data.push({name: item.landpage, val: item.nsess})
			}
			return data
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>

</style>