<template>
	<div>
		<h1>Attribution Report Summary</h1>
		<div>
			<PulloutTopHeros :summarypage="true"></PulloutTopHeros>
		</div>
		<div>
			<h2>Attribution Percentage</h2>
			<p>Attribution is based on web sessions that occur within 30 minutes of an ad playing on the radio.</p>
			<ChartSessionsAttributedPct></ChartSessionsAttributedPct>
		</div>
		<div>
			<h2>Summary - past four weeks</h2>
			<SummarySessionsTableWeekly></SummarySessionsTableWeekly>
		</div>
		<div style="margin: 60px 0 30px;">
			<h2>% of Web Sessions Attributed to Radio Campaign by Daypart</h2>
			<ChartDaypartAttributePct></ChartDaypartAttributePct>
		</div>
		<div style="margin: 30px 0 30px;">
			<h2>Compare Period Activity</h2>
			<SummaryActivityCompareTable></SummaryActivityCompareTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import PulloutTopHeros from "@/components/pageviews/Dashboard/Elements/PulloutTopHeros";
import ChartSessionsAttributedPct from "@/components/pageviews/Dashboard/Elements/ChartSessionsAttributedPct";
import SummarySessionsTableWeekly from "@/components/pageviews/Dashboard/Elements/SummarySessionsTableWeekly";
import ChartDaypartAttributePct from "@/components/pageviews/Dashboard/Elements/ChartDaypartAttributedPct";
import SummaryActivityCompareTable from "@/components/pageviews/Dashboard/Elements/SummaryActivityCompareTable";

export default {
	name: "DashboardSummary",
	components: {
		SummaryActivityCompareTable,
		ChartDaypartAttributePct,
		SummarySessionsTableWeekly,
		ChartSessionsAttributedPct,
		PulloutTopHeros
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>